import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import { usePromiseTracker } from "react-promise-tracker";
// import Loader from "react-promise-loader";
import axios from "axios";

// ----------- routes middlewares
import {
  TesterGuestRoute,
  TesterProtectedRoute,
  CompanyGuestRoute,
  CompanyProtectedRoute,
  SemiProtectedRoute,
} from "./protectedRoutes";

import allRoutes from "./data/routes";
import { Backdrop } from "@material-ui/core";
import ResendEmail from "./utils/ResendEmail";

import Aos from "aos";
import "aos/dist/aos.css";
import BackDropLoader from "./assets/svg/loader.gif";

// const OtherComponent = React.lazy(() => import('./OtherComponent'));
const CompanyHomepage = lazy(() => import("./pages/CompanyHomepage"));
const TesterHomepage = lazy(() => import("./pages/TesterHomepage"));
const Testerlogin = lazy(() => import("./pages/auth/TesterLogin"));
const TesterSignup = lazy(() => import("./pages/auth/TesterSignup"));
const CompanyLogin = lazy(() => import("./pages/auth/CompanyLogin"));
const CompanySignup = lazy(() => import("./pages/auth/CompanySignup"));

const C_HOME = "./Components/CompanyHomepage";
// new in pages
const TesterDashboard = lazy(() => import("./pages/tester/Dashboard"));
const TestDetail = lazy(() => import("./pages/company/TestDetail"));
const ProductPage = lazy(() => import(`${C_HOME}/ProductPage/ProductPage`));
const MarketPage = lazy(() => import(`${C_HOME}/MarketPage/MarketPage`));
const DesignPage = lazy(() => import(`${C_HOME}/DesignPage/DesignPage`));
const PrivacyPolicy = lazy(() => import("./Components/PrivacyPolicy/Policy"));
const TermsAndConditions = lazy(() =>
  import("./Components/TermsAndConditions/TermsAndConditions")
);

const CompanyDashboard = lazy(() => import("./pages/company/Dashboard"));
const CompanyProfile = lazy(() => import("./pages/company/CompanyProfile"));
const CompanyBuyCredits = lazy(() =>
  import("./pages/company/CompanyBuyCredits")
);
const Recording = lazy(() => import("./pages/company/Recording"));
const Start = lazy(() => import("./pages/tester/StartTest"));
const TestRuleDownloadFile = lazy(() =>
  import("./pages/tester/TestRuleDownloadFile")
);
const CustomizedSnackbars = lazy(() => import("./utils/Snackbar"));
const EmailVerification = lazy(() => import("./utils/EmailVerification"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const ChangePassword = lazy(() => import("./pages/auth/ChangePassword"));
const Testers = lazy(() => import("./pages/company/Testers"));
const TesterExtraDetail = lazy(() => import("./pages/tester/ExtraDetail"));
const Error404 = lazy(() => import("./Components/error/Error404"));

//

axios.defaults.withCredentials = true;

// import TaskVideo from './Components/CompanyDashboard/CompanyHome/CompanyCard/TaskDetails/TaskVideo/TaskVideo'

// axios.interceptors.response.use((res) => {
//   console.log("response");
//   return res;
// });

const Loading = () => {
  return (
    <Backdrop open={true} style={{ zIndex: 100000 }}>
      {/* <CircularProgress color="primary" /> */}
      <img
        src={BackDropLoader}
        style={{ widht: "80px", height: "80px" }}
        alt="loading..."
      />
    </Backdrop>
  );
};

function App({ Ga4 }) {
  const { promiseInProgress } = usePromiseTracker();
  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const location = useLocation();

  React.useEffect(() => {
    if (Ga4) {
      console.log("this is ga4", Ga4);
      Ga4.pageview(location.pathname);
    }
  }, [location]);

  return (
    <Suspense fallback={<Loading />}>
      <React.Fragment>
        <CustomizedSnackbars />
        <Switch>
          {/* public pages = guest pages  */}
          <Route exact path={allRoutes.companyHomepage}>
            <CompanyHomepage />
          </Route>
          <Route exact path={allRoutes.testerHomepage}>
            <TesterHomepage />
          </Route>
          <TesterGuestRoute exact path={allRoutes.testerSignup}>
            <TesterSignup />
          </TesterGuestRoute>
          <TesterGuestRoute exact path={allRoutes.testerLogin}>
            <Testerlogin />
          </TesterGuestRoute>
          <CompanyGuestRoute exact path={allRoutes.companyLogin}>
            <CompanyLogin />
          </CompanyGuestRoute>
          <CompanyGuestRoute exact path={allRoutes.companySignup}>
            <CompanySignup />
          </CompanyGuestRoute>
          <Route exact path={allRoutes.companyProduct}>
            <ProductPage />
          </Route>
          <Route exact path={allRoutes.companyMarket}>
            <MarketPage />
          </Route>
          <Route exact path={allRoutes.companyDesign}>
            <DesignPage />
          </Route>
          <Route path={allRoutes.forgotPassword}>
            <ForgotPassword />
          </Route>
          <Route path={allRoutes.changePassword}>
            <ChangePassword />
          </Route>
          <Route path={allRoutes.emailVerification}>
            <EmailVerification />
          </Route>
          <Route path={allRoutes.privacyPolicy}>
            <PrivacyPolicy />
          </Route>
          <Route path={allRoutes.termsAndConditions}>
            <TermsAndConditions />
          </Route>
          <Route path={allRoutes.resendEmail + "/:token"}>
            <ResendEmail />
          </Route>
          <Route path={`${allRoutes.testerTestRulesDownloadFile}/:value`}>
            <TestRuleDownloadFile />
          </Route>

          {/*  SEMI PRIVATE ROUTES  */}
          <SemiProtectedRoute path={allRoutes.testerExtraDetail}>
            <TesterExtraDetail />
          </SemiProtectedRoute>

          {/* PRIVATE PAGES */}
          {/* ---- TESTER */}
          <TesterProtectedRoute
            path={allRoutes.testerDashboard}
            component={TesterDashboard}
          />

          <TesterProtectedRoute
            path={`${allRoutes.testerStartTest}/:value`}
            component={Start}
          />

          {/* ---- COMPANY */}
          <CompanyProtectedRoute
            path={allRoutes.companyDashboard}
            component={CompanyDashboard}
          />
          <CompanyProtectedRoute
            path={allRoutes.companyProfile}
            component={CompanyProfile}
          />
          <CompanyProtectedRoute
            path={allRoutes.companyBuyCredits}
            component={CompanyBuyCredits}
          />
          <CompanyProtectedRoute
            path={allRoutes.companyTestDetail}
            exact
            component={TestDetail}
          />
          <CompanyProtectedRoute
            path={allRoutes.companyTestTesters}
            exact
            component={Testers}
          />
          <CompanyProtectedRoute
            path="/recording/:testId/:testerId"
            exact
            component={Recording}
          />

          <Route exact path="/">
            <Redirect to={allRoutes.companyHomepage} />
          </Route>

          {/* ERROR PAGE */}
          <Route exact path="*" component={Error404} />
          {/* <Route path = "*">
          <Redirect to={allRoutes.companyLogin} />
        </Route> */}

          {/* authentication route  */}
          {/* <Route path="/testersignup" exact component={TesterSignup} /> */}
          {/* <Route path="/opinionatorlogin" exact component={Testerlogin} /> */}
          {/* <Route path="/companylogin" exact component={CompanyLogin} /> */}
          {/* <Route path="/companysignup" exact component={CompanySignup} /> */}
          {/* <Route path="/testerdashboardcontainer" component={TesterDashboard} /> */}
          {/* <Route path="/companydashboardcontainer" component={CompanyDashboard} /> */}
          {/* <Route path="/start/:value" component={Start} /> */}
          {/* <Route exact path="/test-detail/:value" component={TestDetail} /> */}
          {/* <Route exact path="/recording/:value" component={Recording} /> */}
          {/* <Route exact> <Redirect to="/opinionatorlogin" /> </Route> */}

          {/* <Route exact path="/taskvideo/:value" component={TaskVideo} /> */}
          {/* <Route path="/rightComplete" exact component={TesterDashboard} /> */}
          {/* <Route path="/start-test/:value" component={StartTest}/>
            <Route path="/begin-test/:value" component={BeginTest} /> */}
        </Switch>
        {promiseInProgress && <Loading />}

        {/* <Loader  promiseTracker={usePromiseTracker} /> */}
      </React.Fragment>
    </Suspense>
  );
}

export default App;
