import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./Context/AuthContext";
import { MuiThemeProvider, responsiveFontSizes } from "@material-ui/core";
import theme from "./DefaultTheme";
import GA4React from "ga-4-react";

// const theme1 = responsiveFontSizes(theme);

// import axios from "axios";

// const instance = axios.create()
// axios.interceptors.response.use((response) => {
//   // here i have to check the user is authenticated or not
//   console.log(response, "middleware ");
//   console.log("hello");
//   return response;
// });

// axios.interceptors.request.use((response) => {
//   // here i have to check the user is authenticated or not
//   console.log(response, "middleware request ");
//   console.log("hello");
//   return response;
// });

// setting jwt token in headers by default
// axios.defaults.headers.common["Authorization1"] =
// localStorage.getItem("companyToken");

const ga4react = new GA4React("G-SHZ5FH457H");
ga4react
  .initialize()
  .then((ga4) =>
    ReactDOM.render(
      <BrowserRouter>
        <AuthProvider>
          <MuiThemeProvider theme={theme}>
            <App Ga4={ga4} />
          </MuiThemeProvider>
        </AuthProvider>
      </BrowserRouter>,
      document.getElementById("root")
    )
  )
  .catch((e) =>
    ReactDOM.render(
      <BrowserRouter>
        <AuthProvider>
          <MuiThemeProvider theme={theme}>
            <App />
          </MuiThemeProvider>
        </AuthProvider>
      </BrowserRouter>,
      document.getElementById("root")
    )
  );

// ReactDOM.render(
//   <BrowserRouter>
//     <AuthProvider>
//       <MuiThemeProvider theme={theme}>
//         <App Ga4={ReactGA} />
//       </MuiThemeProvider>
//     </AuthProvider>
//   </BrowserRouter>,
//   document.getElementById("root")
// );
