import React from "react";
import { Route, Redirect } from "react-router-dom";

const CompanyGuestRoute = ({ children, ...rest }) => {
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (type === "tester" && token) {
          return (
            <Redirect
              to={{
                pathname: "/opinionator/dashboard",
                state: { from: location },
              }}
            />
          );
        } else if (token) {
          return (
            <Redirect
              to={{ pathname: "/company/dashboard", state: { from: location } }}
            />
          );
        } else return children;
      }}
    />
  );
};

export default CompanyGuestRoute;
