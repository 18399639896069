import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import DOOFLOGO from "../../assets/Doof_logo.svg";

import allRoutes from "../../data/routes";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  button: {
    padding: "8px 25px",
    ["@media (max-width:450px)"]: {
      padding: "8px 15px",
    },
    ["@media (max-width:380px)"]: {
      padding: "4px 15px",
    },
    ["@media (max-width:360px)"]: {
      display: "none",
    },
  },
});

function Navbar({
  url,
  buttonText,
  links,
  homeLogoLink = allRoutes.companyHomepage,
}) {
  // const [scrolling, setScrolling] = useState(false);

  // useEffect(() => {
  //   const handleScroll = (event) => {
  //     console.log("scrollign", window.scrollY);
  //     if (window.scrollY < 40) {
  //       // console.log("less then 10", scrolling);
  //       setScrolling(false);
  //     } else if (window.scrollY > 40) {
  //       // console.log("greater then 10", scrolling);
  //       setScrolling(true);
  //     }
  //   };

  //   // var mainListDiv = document.getElementById("mainListDiv"),
  //   // mediaButton = document.getElementById("mediaButton");

  //   // mediaButton.onclick = function () {
  //   //   // "use strict";
  //   //   mainListDiv.classList.toggle("show_list");
  //   //   mediaButton.classList.toggle("active");
  //   // };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);  // eslint-disable-next-line react-hooks/exhaustive-deps

  const classes = useStyles();

  return (
    <nav
      className="navbar"
      // style={{
      //   background: "black",
      //   boxShadow: scrolling
      //     ? "-2px 1px 15px -3px rgba(53,53,53,0.55)"
      //     : "none",
      // }}
    >
      <div className="logo">
        <Link to={homeLogoLink} style={{ textDecoration: "none" }}>
          <img src={DOOFLOGO} alt="Doof Logo" />
          <span>BETA</span>
        </Link>
      </div>
      <div id="mainListDiv" className="main_list">
        <ul>
          {links?.map((link, i) => (
            <li key={`link_${i}`}>
              <Link to={link.url} className="nav-link">
                {link.title}
              </Link>{" "}
            </li>
          ))}
          {buttonText && <li>
            <Link to={url} className="nav-link">
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
              >
                {buttonText}
              </Button>
            </Link>{" "}
          </li>}
        </ul>
      </div>
    </nav>
  );
  // }
}

export default Navbar;
