const allRoutes = {
  //landingPage
  companyHomepage: "/",
  testerHomepage: "/opinionator/home",

  //common url
  emailVerification: "/verify-email/activate-account/:token",
  forgotPassword: "/forgot-password",
  changePassword: "/change-password/:token",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
  resendEmail: "/resend-email",

  // tester urls
  testerLogin: "/opinionator/login",
  testerSignup: "/opinionator/signup",
  testerDashboard: "/opinionator/dashboard",
  testerPayment: "/opinionator/dashboard/payment",
  testerCompletedTest: "/opinionator/dashboard/completed-test",
  testerProfile: "/opinionator/dashboard/profile",
  testerTestRules: "/opinionator/dashboard/test-rules",
  testerTestRulesDownloadFile:
    "/opinionator/dashboard/test-rules/download-file",
  testerStartTest: "/opinionator/start",
  testerExtraDetail: "/opinionator/extra-detail",

  // company urls
  companyLogin: "/companylogin",
  companySignup: "/companysignup",
  companyProduct: "/company/product",
  companyMarket: "/company/market",
  companyDesign: "/company/design",
  companyDashboard: "/company/dashboard",
  companyPayment: "/company/dashboard/payment",
  companyCompletedTest: "/company/dashboard/completed-test",
  companyIncompletedTest: "/company/dashboard/incompleted-test",
  companyNewproject: "/company/dashboard/newproject",
  companyTestDetail: "/company/test-detail/:value",
  companyTestTesters: "/company/opinionators/:value",
  companyProfile: "/company/profile",
  companyBuyCredits: "/company/buy-credits",
};

module.exports = allRoutes;
