import React from "react";
import { Route, Redirect } from "react-router-dom";

const TesterGuestRoute = ({ children, ...rest }) => {
  const token = localStorage.getItem("token");
  const verified = JSON.parse(localStorage.getItem("verified"));
  const type = localStorage.getItem("verified");

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (type === "company") return children;
        if (token && verified) {
          return (
            <Redirect
              to={{ pathname: "/opinionator/dashboard", state: { from: location } }}
            />
          );
        } else if (token && !verified) {
          return (
            <Redirect
              to={{
                pathname: "/opinionator/extra-detail",
                state: { from: location },
              }}
            />
          );
        } else return children;
      }}
    />
  );
};

export default TesterGuestRoute;
