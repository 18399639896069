import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      dark: "#FFE999",
      main: "#F7D832",
      light: "rgba(255, 187, 0, 0.13)",
      // light: "#FFF3B6",
      // this light color is a bg color of start test in tester
      // light: "#ffef9d",
    },
    secondary: {
      light: "#73737",
      // main: "#686868",
      main: "rgba(0, 0, 0, 0.6)",
      dark: "#b7b7b7",
    },
    info: {
      main: "#0F5EF7",
      light: "#0a41ac",
      dark: "#3f7ef8",
    },
  },
  typography: {
    // on changing html:{font-size :  10px; } material ui also work with 10px by default
    fontFamily: "Montserrat, sans-serif",
    // fontFamily: "Roboto, sans-serif",
    //   fontFamily: `"Open Sans"`,
    //   h1: {
    //     // fontSize: "3rem", // 64px=4rem
    //     // fontWeight: "600",
    //     fontSize: "2.5rem", //40px
    //     fontWeight: "bold",
    //   },
    //   h2: {
    //     fontSize: "1.375rem", // 22px = 1.375rem
    //     fontWeight: "bold",
    //   },
    //   h3: {
    //     fontSize: "1.125rem", // 18px = 1.125rem
    //     fontWeight: 600,
    //   },
    //   h4: {
    //     fontSize: "0.875rem", // 14px = 0.875rem
    //     fontWeight: 600,
    //   },
    //   h5: {
    //     fontSize: "0.75rem", // 12px = 0.75rem
    //     fontWeight: 400,
    //   },
    //   h6: {
    //     fontSize: "0.7rem", // 10px = 0.625rem
    //     fontWeight: 600,
    //   },

    //   // For paragraph
    //   body1: {
    //     // fontSize: "1.1rem",
    //     fontSize: "0.875rem", //14px
    //     fontFamily: 'poppins',
    //     // fontWeight: 500,
    //   },

    //   // used in paragraph
    //   subtitle1: {
    //     fontSize: "0.875rem", // 14px = 0.875rem
    //     fontWeight: 400,
    //   },

    //   // subtite2 is used in testDetail card
    //   subtitle2: {
    //     fontSize: "2.25rem", //36px= 2.25rem
    //     fontWeight: 700,
    //   },
    // },

    // in used
    // testdetail heading , tester company home heading
    h1: {
      fontSize: "3rem", //48px
      fontWeight: "bold",
      letterSpacing: ".4px",
    },
    // in used
    h2: {
      fontSize: "2.5rem", // 40px
      fontWeight: "bold",
    },
    // in used // testdetail question heading
    h3: {
      fontSize: "1.9rem", // 30px
      fontWeight: 600,
    },
    // in used
    h4: {
      fontSize: "1.563rem", // 25px
      fontWeight: "bold",
    },
    // in use
    h5: {
      fontSize: "1.25rem", // 20px
      fontWeight: 400,
    },
    // in use
    h6: {
      fontSize: "1rem", // 16px
      fontWeight: 600,
    },

    body2: {
      fontSize: "0.875rem", // 14px
      fontWeight: 400,
      letterSpacing: "-0.01em",
      // fontSize: "0.675", // 14px
      // fontWeight: 400,
    },
    // in used
    subtitle1: {
      fontSize: "1rem", // 16px = 0.875rem
      fontWeight: 400,
      letterSpacing: ".4px",
    },
    subtitle2: {
      fontSize: ".75rem", // 12px = 0.75rem
      fontWeight: 400,
      letterSpacing: ".4px",
    },

    caption: {
      fontSize: ".525rem", // 9px = 0.525rem
      fontWeight: 400,
      letterSpacing: ".4px",
    },
  },

  overrides: {
    // table
    MuiTableCell: {
      root: {
        fontSize: "16px",
        borderBottom: "none",
        fontWeight: 500,
        textTransform: "inherit",
      },
      head: {
        fontWeight: 600,
      },
    },
    // button
    MuiButton: {
      root: { borderRadius: "5px", fontWeight: "bold" },
    },

    MuiOutlinedInput: {
      notchedOutline: {
        border: 'none'
      }
    }
  },
});

export default theme;
