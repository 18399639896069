let REACT_APP_BACKEND_URL = "";

console.log(
  REACT_APP_BACKEND_URL,
  "REACT_APP_BACKEND_URL",
  process.env.NODE_ENV
);
// default heroku is also development part not production part
// if (process.env.NODE_ENV !== "development")
REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// REACT_APP_BACKEND_URL = "http://localhost:8000";

export default REACT_APP_BACKEND_URL;
