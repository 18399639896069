import React, { useContext } from "react";
import ServerService from "../apis/common";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import allRoutes from "../data/routes";

import { ReactComponent as EmailSvg } from "./verifyEmail.svg";
import {
  Box,
  makeStyles,
  Typography,
  Button,
  Container,
} from "@material-ui/core";
import TesterNavbar from "../Components/Navbar new/TesterNavbar";
import { AuthContext } from "../Context/AuthContext";
import Navbar from "../Components/Navbar/Navbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 0",
    minHeight: "100vh",
    // maxWidth: "500px",

    Logo: {
      position: "absolute",
      left: "20px",
      top: "15px",
    },
  },
}));

function ResendEmail() {
  const location = useLocation();
  const { setAlertData } = useContext(AuthContext);
  const { token } = useParams();
  const classes = useStyles();
  const email = localStorage.getItem("email");
  console.log(location, "dfsjlfjs");
  const history = useHistory();
  const sendEmail = () => {
    ServerService.resendEmail(token)
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          setAlertData({
            open: true,
            title: result.data.message || "succesfully send email",
            type: "success",
            isNav: true,
          });
        }
      })
      .catch((err) => {
        if (
          !err ||
          !err?.response ||
          !err?.response?.data ||
          !err?.response?.data?.message
        ) {
          return setAlertData({
            open: true,
            title: "Server Error",
            type: "error",
            isNav: true,
          });
        }
        const { message } = err?.response?.data;
        setAlertData({
          open: true,
          title: message || "Server Error",
          type: "error",
          isNav: true,
        });
      });
  };

  return (
    <Container className={classes.root}>
      <Navbar
        url={allRoutes.testerSignup}
        // buttonText="Sign Up"
        homeLogoLink={allRoutes.testerHomepage}
        links={[]}
      />
      {/* <TesterNavbar onlyLogo={true} /> */}
      <Box style={{ maxWidth: "540px", margin: "0px 1rem" }}>
        {/* <EmailSvg style={{ width: "100%", marginBottom: "2rem" }} /> */}

        {location?.state === undefined ? (
          <Typography gutterBottom align="center" variant="h4">
            Great! You've successfully signed up.
          </Typography>
        ) : (
          <Typography gutterBottom align="center" variant="h4">
            Account not verified.
          </Typography>
        )}

        <Typography gutterBottom align="center" variant="body2">
          We've sent you a link to confirm your email address, please check your
          inbox.
        </Typography>

        <Button
          variant="contained"
          style={{ margin: "1rem 0" }}
          color="primary"
          fullWidth
          onClick={sendEmail}
        >
          Resend Email
        </Button>
        <Typography gutterBottom variant="body2">
          Didn't receive the email?
        </Typography>
        <Typography gutterBottom variant="body2">
          1. Is <span style={{ fontWeight: 600 }}> {email}</span> your email
          address correct? If not, you can sign up again&nbsp;
          <Link style={{ fontWeight: 600 }} to={allRoutes.testerSignup}>
            {/* {" "} */}
            here.
          </Link>
        </Typography>
        <Typography gutterBottom variant="body2">
          2. Please check your spam/junk mail folder.
        </Typography>
      </Box>
    </Container>
  );
}

export default ResendEmail;
