import React, { createContext, useState } from "react";
// import ServerService from '../ServerService';

export const AuthContext = createContext();

export default function ({ children }) {
  // these two is removed

  // const [testerData, setTesterData] = useState(null);
  // const [companyData, setCompanyData] = useState(null);
  // --------  this state is updated state mean this is new ---------

  const [isAuth, setIsAuth] = useState(false);

  // all uncompleted tests store here
  const [uncompletedTests, setUncompletedTests] = useState(null);

  // all completed tests store here
  const [completedTests, setCompletedTests] = useState([]);

  // all completed tests store here
  const [draftsTests, setDraftsTests] = useState([]);

  // all test store here
  const [tests, setTests] = useState([]);

  // if company then company data store here if tester means vise versa
  const [user, setUser] = useState(null);

  // start test saving answers
  const [answers, setAnswers] = useState({});

  //deleted questions
  const [deletedQuestionImages, setdeletedQuestionImages] = useState([]);

  // snackbar for alerts
  const [alertData, setAlertData] = useState({
    open: false,
    title: "none",
    type: "error",
    isNav: false,
  });
  // useEffect(() => {
  //     // AuthService.isAuth().then(data=>{
  //     //     console.log(data)
  //     //     setUser(data.data.message);
  //     //     setIsAuth(data.data.isAuth);
  //     //     // setIsLoaded(true);
  //     // })
  //     // return isAuth;
  // }, [])

  const [draftClickData, setDraftClickData] = useState(null);

  //file size
  const [totalFileSize, settotalFileSize] = useState(0);

  return (
    <AuthContext.Provider
      value={{
        // testerData, setTesterData,
        // companyData, setCompanyData,
        uncompletedTests,
        setUncompletedTests,
        completedTests,
        setCompletedTests,
        draftsTests,
        setDraftsTests,
        user,
        setUser,
        tests,
        setTests,
        alertData,
        setAlertData,
        isAuth,
        setIsAuth,
        answers,
        setAnswers,
        draftClickData,
        setDraftClickData,
        totalFileSize,
        settotalFileSize,
        deletedQuestionImages,
        setdeletedQuestionImages,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
