import { makeStyles } from "@material-ui/core";

export const globalStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: theme.spacing(10, 10),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
  },
  imgContainer: {
    "& img": {
      height: "100%",
      width: "100%",
    },
    "& > span": {
      height: "100%",
      width: "100%",
    },
  },
  hero_title: {
    fontWeight: "bold",
    // fontSize: 'clamp(24px, 4vw, 42px)', // because i have applied h1 variant
    lineHeight: "1.2",
    marginBottom: "20px",
  },
  hero_title_description: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  hero_btn: {
    padding: "8px 25px",
  },
  cards_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card_media: {
    height: 150,
    "& img": {
      height: "100%",
      width: "100%",
    },
    "& > span": {
      height: "100%",
      width: "100%",
    },
  },
  cards_title_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  cards_title: {
    fontWeight: "bold",
    textAlign: "center",
  },
  cards_title_description: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
