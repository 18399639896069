import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
const SemiProtectedRoute = ({ children, ...rest }) => {
  const verified = ""
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");

  if(type === "opinionator"){
    verified = JSON.parse(localStorage.getItem("verified"));
  }

  const cookies = new Cookies();
  console.log(verified, token, "----", cookies.get("token"));

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (type === "company" && token) {
          return (
            <Redirect
              to={{ pathname: "/company/dashboard", state: { from: location } }}
            />
          );
        } else if (!token) {
          return (
            <Redirect
              to={{ pathname: "/opinionatorlogin", state: { from: location } }}
            />
          );
        } else if (verified && token) {
          return (
            <Redirect
              to={{ pathname: "/opinionator/dashboard", state: { from: location } }}
            />
          );
        } else return children;
      }}
    />
  );
};

export default SemiProtectedRoute;
