import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import logo from "../../assets/Doof_logo.svg";
import { globalStyles } from "../globalStyles";
import { Link } from "react-router-dom";
import allRoutes from "../../data/routes";
// import allRoutes from "../../data/routes";
const useStyles = makeStyles((theme) => ({
  nav: {
    // border: '1px dashed',
    backgroundColor: "#000",
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    padding: theme.spacing(0, 3),
    position: "fixed",
    width: "100%",
    top: 0,
    height: "75px",
    zIndex: "999999",
    [theme.breakpoints.down("sm")]: {},
  },
  logo: {
    display: "flex",
    zIndex: 6,
    textDecoration: "none",
    "& img": {
      width: "90%",
    },
    "& span": {
      color: "white",
      letterSpacing: "2px",
      margin: theme.spacing("auto", 0, 0, 1),
      fontWeight: "500",
    },
    [theme.breakpoints.down("sm")]: {
      "& img": {
        width: "60%",
      },
    },
  },
  nav_links: {
    display: "flex",
    listStyle: "none",
    "& li": {
      padding: theme.spacing(1, 2),
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        margin: "20px auto",
      },
      "& a": {
        color: "white",
        textDecoration: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      zIndex: "5",
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
      top: 0,
      left: 0,
      transition: "all 0.3s ease-in-out",
    },
    [theme.breakpoints.up("md")]: {
      transform: "translateY(0%) !important",
    },
  },
  nav_link_active: {
    [theme.breakpoints.down("sm")]: {
      transform: "translateY(0%)",
    },
  },
  menu_btn: {
    display: "none",
    zIndex: "6",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

export default function Navbar({ onlyLogo = false }) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [navToggle, setnavToggle] = useState(false);

  // const anchorRef = React.useRef(null);

  return (
    <>
      <nav
        className={classes.nav}
        style={{ justifyContent: onlyLogo ? "center" : "space-between" }}
      >
        <Link to={allRoutes.companyHomepage} className={classes.logo}>
          <img src={logo} alt="Doof Logo" />
          <span>BETA</span>
        </Link>

        {!onlyLogo && (
          <ul
            className={classes.nav_links}
            style={{
              transform: navToggle ? "translateY(0%)" : "translateY(-100%)",
              marginBottom: "0",
            }}
          >
            <li>
              {" "}
              <Link to={allRoutes.testerLogin}>
                <Button
                  variant="contained"
                  color="primary"
                  className={globalClasses.hero_btn}
                  disableElevation
                  disableRipple
                >
                  Login
                </Button>
              </Link>
            </li>
            <li>
              {" "}
              <Link to={allRoutes.testerSignup}>
                <Button
                  variant="contained"
                  color="primary"
                  className={globalClasses.hero_btn}
                  disableElevation
                  disableRipple
                >
                  Sign Up
                </Button>
              </Link>
            </li>
          </ul>
        )}

        <div className={classes.menu_btn}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="menu"
            onClick={() => {
              setnavToggle(!navToggle);
            }}
          >
            {navToggle ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </div>
      </nav>
    </>
  );
}
