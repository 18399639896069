import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({
  component: Component,
  path,
  location,
  match,
  ...rest
}) => {
  const token = localStorage.getItem("token") ? true : false;
  const verified = JSON.parse(localStorage.getItem("verified"));
  const type = localStorage.getItem("type");

  return (
    <Route
      {...rest}
      path={path}
      exact
      render={({ location }) => {
        if (type === "company" && token) {
          return (
            <Redirect
              to={{ pathname: `/company/dashboard`, state: { from: location } }}
            />
          );
        } else if (!token && !verified) {
          return (
            <Redirect
              to={{ pathname: `/opinionatorlogin`, state: { from: location } }}
            />
          );
        } else if (token && !verified) {
          return (
            <Redirect
              to={{
                pathname: `/opinionator/extra-detail`,
                state: { from: location },
              }}
            />
          );
        } else return <Component />;
      }}
    />
  );
};

export default ProtectedRoute;
