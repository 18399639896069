import axios from "axios";
import REACT_APP_BACKEND_URL from "../configs";
import allRoutes from "../data/routes";
export const BASE_URL = REACT_APP_BACKEND_URL + "/api" || "/api";
axios.defaults.withCredentials = true;

const axiosInstance = (history = null) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  instance.interceptors.response.use(
    (response) => {
      console.log("middleware ", response);
      return response;
    },
    (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }
      if (error.response.status === 401) {
        localStorage.clear();
        if (history) {
          history.push(allRoutes.companyHomepage);
        } else {
          window.location = allRoutes.companyHomepage;
        }
      } else {
        return Promise.reject(error);
      }
    }
  );

  instance.interceptors.request.use(
    (request) => {
      console.log(request, "request");
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosInstance;
