import axios from "axios";
import { trackPromise } from "react-promise-tracker";
// import REACT_APP_BACKEND_URL from "../configs";
// require('dotenv').config()
import axiosInstance from "./instance";

axios.defaults.withCredentials = true;

// export const BASE_URL = REACT_APP_BACKEND_URL + "/api" || "/api";

// function testerAuthRequest() {
//   const token = localStorage.getItem("token");
//   return axios.create({
//     baseURL: BASE_URL,
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// }

const ServerService = {
  // getting common data from database
  getCommonData: () => {
    return trackPromise(axiosInstance().get("/common-data"));
  },

  // verifying email or acitvating account
  verifyEmail: (data) => {
    return trackPromise(axiosInstance().post("/verifying-email", data));
  },

  // verifying email or acitvating account
  forgotPassword: (data) => {
    return trackPromise(axiosInstance().post("/forgot-password", data));
  },

  changePassword: (data) => {
    return trackPromise(axiosInstance().post("/change-password", data));
  },

  // tester logout
  Logout: () => {
    return trackPromise(axiosInstance().get("/logout"));
  },

  existUsername: (data, token) => {
    return trackPromise(
      axiosInstance().post("/exist-username", data, { cancelToken: token })
    );
  },
  resendEmail: (token) => {
    return trackPromise(axiosInstance().get(`/resend-email/${token}`));
  },
};

export default ServerService;
